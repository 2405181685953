@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&family=Ubuntu:wght@300;400;600&display=swap');
:root {
  --background-color-one: #0F0F0F;
  --background-color-two: white;
  /* --background-color-three: #0F0F0F; */
  --background-color-four: #343a40;
  --background-color-five: rgba(0, 0, 0, 0.8);
  --background-color-dark: #343a40;
  --text-color-one: white;
  --text-color-two: #afafaf;
  --text-color-three: orange;
  --box-shadow-one: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  --link-color: #AACCFF;
  --default-font-bigger: 1.1rem;
  --default-font-size: 1rem;
  --default-font-small: 0.9rem;
  --default-font-xs: 0.8rem;
}

body, .body {
  font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: var(--background-color-one);
  color: var(--text-color-one);
  font-weight: 300;
  font-size: var(--default-font-small);
  text-align: left;
}

@media only screen and (min-width: 576px) {
  body, .body {
    font-size: unset;
    text-align: center;
  }
}

/* general */

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.margin-bot, .card-link.margin-bot, .card-text.margin-bot {
  margin-bottom: 30px;
}

.margin-left-5 {
  margin-left: 5px;
}

.text-align-left {
  text-align: left;
}

/* section */

.section-header {
  border-bottom: 0.2px solid var(--text-color-one);
  display: inline-block;
  padding-bottom: 5px;
  letter-spacing: 1px;
  text-indent: 2px;
  font-weight: 600;
  text-align: left;
  font-size: var(--default-font-bigger);
  margin: 0;
  width: 100%;
}

.contact .section-header {
  margin-bottom: 8px;
}

.section {
  padding: 20px 10px;
}

.section-width, .row.section-width {
  margin: auto;
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
}

.section-description {
  font-size: 18px;
  margin: 0;
  border: none;
  padding: 0;
}

.section-body {
  margin-top: 0;
}

@media only screen and (min-width: 576px) {
  .section {
    padding: 90px 10px;
  }
  .section-header {
    text-align: center;
    width: unset;
    font-size: 22px;
  }
  .section-body {
    margin-top: 10px;
  }
  .section-description {
    margin-top: 10px;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .section-body {
    margin-top: 50px;
  }
}

/* link */

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.disabled.card-link {
  color: var(--text-color-two);
}

/* image */

.card-img-top.object-top {
  object-position: top;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img-left {
  width: 200px;
  object-fit: cover;
  height: 200px;
  min-width: 200px;
}

/* banner */

.banner {
  height: unset;
  font-size: 72px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 40px 10px 10px 10px;
}

.banner-content {
  z-index: 2;
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--background-color-four);
  flex-direction: row;
  padding: 5px 10px;
  justify-content: space-between;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
}

.background {
  background: none;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.25;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.name {
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0;
  border: none;
  padding: 0;
}

.social-media-buttons-group {
  display: flex;
  justify-content: center;
  margin: 0;
}

.svg {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: opacity 0.2s;
  display: block;
}

.svg-link {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px var(--background-color-two) solid;
  height: 30px;
  width: 30px;
  transition: opacity 0.3s, border 0.3s;
  background-color: var(--background-color-four);
}

.svg-link:hover {
  border: 1px solid transparent;
}

.svg-link svg {
  height: 50px;
  width: 50px;
  padding: 6px;
}

.svg-link+.svg-link {
  margin-left: 10px;
}

.svg-link:hover {
  opacity: 0.5;
}

@media only screen and (min-width: 576px) {
  .name {
    border-bottom: 0.2px solid var(--text-color-one);
    padding-bottom: 5px;
  }
  .background {
    background: url('./assets/developer.jpeg') !important;
  }
  .social-media-buttons-group {
    margin-top: 20px;
  }
  .banner {
    height: 100vh;
  }
  .banner-content {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    background-color: transparent;
    box-shadow: unset;
  }
  .svg-link {
    height: 42px;
    width: 42px;
  }
  .svg-link svg {
    padding: 10px;
  }
  .svg-link+.svg-link {
    margin-left: 20px;
  }
  .delay-sm {
    opacity: 0;
    transform: translateY(30px);
    pointer-events: none;
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  .delay-sm.show {
    opacity: 1;
    transform: translateY(0px);
    pointer-events: unset;
  }
  .fade-left-sm {
    opacity: 0;
    transform: translateX(-100px);
    transition: opacity 1.2s ease-out, transform 1.2s ease-out;
  }
  .fade-left-sm.show {
    opacity: 1;
    transform: translateX(0px);
  }
  .fade-right-sm {
    opacity: 0;
    transform: translateX(100px);
    transition: opacity 1.2s ease-out, transform 1.2s ease-out;
  }
  .fade-right-sm.show {
    opacity: 1;
    transform: translateX(0px);
  }
}

/* btn */

.btn.btn-main, .btn.btn-secondary {
  width: 100%;
  max-width: 200px;
  margin: 5px 0;
  font-size: 15px;
  border: 1px solid var(--background-color-two);
}

/* footer */

.line {
  background-color: var(--background-color-four);
  height: 0.1px;
  opacity: 0.5;
}

.footer {
  padding: 10px 0 55px 0;
  opacity: 0.5;
  text-align: center;
}

@media only screen and (min-width: 576px) {
  .footer {
    padding: 30px 0;
  }
}

/* projects */

.card-link {
  color: var(--link-color);
  transition: opacity 0.2s;
  margin: 2px;
}

.card-link:hover {
  color: var(--link-color);
}

.card-link+.card-link {
  margin-left: 1.25rem;
}

.flex-col .card-link+.card-link {
  margin-left: 6px;
}

.card-link:hover {
  opacity: 0.5;
}

.card-wrapper .card {
  height: 100%;
  border-radius: 0;
  flex-direction: row;
}

.card-title {
  font-weight: 600;
  line-height: 1.5;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}

.highlight, .description {
  font-weight: bold;
  font-style: italic;
}

.description {
  color: var(--text-color-three);
}

.card-wrapper {
  position: relative;
  width: 100%;
}

.card.background-dark-lg, .background-dark-lg {
  background-color: transparent;
}

.card-wrapper+.card-wrapper {
  border-top: 0.1px solid var(--background-color-four);
}

.top-bot-border-sm {
  border-top: 0.1px solid var(--background-color-four);
  border-bottom: 0.1px solid var(--background-color-four);
}

.top-bot-border-xs {
  border-top: 0.1px solid var(--background-color-four);
  border-bottom: 0.1px solid var(--background-color-four);
}

@media only screen and (min-width: 576px) {
  .top-bot-border-xs {
    border-top: none;
    border-bottom: none;
  }
}

@media only screen and (min-width: 768px) {
  .card-wrapper .card {
    flex-direction: column;
  }
  .card.background-dark-lg, .background-dark-lg {
    background-color: var(--background-color-dark);
  }
  .card-img-top {
    height: 220px;
    object-fit: cover;
  }
  .card-wrapper {
    max-width: 33.333333%;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 20px;
  }
  .card-body {
    text-align: center;
  }
  .card-wrapper+.card-wrapper {
    border-top: none;
  }
  .top-bot-border-sm {
    border-top: none;
    border-bottom: none;
  }
}

.collapsed {
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 0.5s;
}

.collapsed.active {
  max-height: 500px;
}

/* articles */

.long-card {
  width: 100%;
  min-height: 100px;
  text-align: left;
  color: var(--text-color-two);
  display: flex;
  margin: 0;
  border: none;
}

.long-card:hover {
  text-decoration: none;
  color: var(--text-color-two);
}

.long-card:hover .title {
  color: var(--link-color);
  text-decoration: underline
}

.long-card .title {
  font-size: var(--default-font-size);
  font-weight: 600;
  color: var(--text-color-one);
  transition: color 0.2s;
  line-height: 1.5;
}

.long-card-body, .card .card-body {
  padding: 20px 0;
}

.long-card-body {
  width: 100%;
}

.long-card+.long-card {
  border-top: 0.1px solid var(--background-color-four);
}

@media only screen and (min-width: 576px) {
  .long-card {
    border: 0.1px solid var(--background-color-four);
    margin-bottom: 10px;
  }
  .long-card-body {
    padding: 40px 30px;
  }
  .card .card-body {
    padding: 20px;
  }
}

/* remarks and label */

.remarks {
  font-size: var(--default-font-small);
}

.label {
  font-size: var(--default-font-small);
  border: 0.1px solid var(--text-color-two);
  display: inline-block;
  margin: 5px;
  padding: 1px 5px;
  transition: opacity 0.2s;
}

.label:hover {
  cursor: pointer;
  opacity: 0.5;
}

.label.active {
  border: 0.1px solid var(--link-color);
  color: var(--link-color);
}

.label-wrapper {
  margin-left: -5px;
}

@media only screen and (min-width: 768px) {
  .label-wrapper {
    margin-left: 0px;
  }
}

/* navigation */

.nav {
  display: flex;
  justify-content: space-evenly;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-color-four);
  padding: 10px 0;
  font-size: var(--default-font-small);
}

.nav .card-link {
  color: var(--text-color-one);
  cursor: pointer;
}

.logo {
  color: transparent;
  font-weight: 600;
  margin-right: auto;
  margin-left: 20px;
  transition: color 0.3s;
}

.logo.show {
  color: var(--text-color-one);
}

@media only screen and (min-width: 576px) {
  .nav {
    top: 0;
    bottom: unset;
    z-index: 2;
    justify-content: flex-end;
    background-color: transparent;
    transition: background-color 0.3s, box-shadow 0.3s;
  }

  .nav.opaque {
    background-color: var(--background-color-four);
    box-shadow: var(--box-shadow-one);
  }

  .nav .card-link {
    font-size: var(--default-font-small);
    margin: 0 20px;
  }
}

/* font sizes */

.card .card-title, .long-card .title, .footer, .footer .remarks, .name, .btn.btn-main, .card-link {
  font-size: var(--default-font-small);
}

.label, .card-text, .card .card-link, .remarks, .section-description {
  font-size: var(--default-font-xs);
}

@media only screen and (min-width: 576px) {
  .name {
    font-size: 62px;
  }
  .banner .section-description {
    font-size: 20px;
  }
  .card .card-title, .long-card .title, .card-link {
    font-size: var(--default-font-bigger);
  }
  .label, .card-text, .card .card-link, .remarks, .section-description, .btn.btn-main, .btn.btn-secondary, .footer, .footer .remarks {
    font-size: var(--default-font-small);
  }
}